import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import { 
  Typography, 
  Card,
  CardHeader,
  Avatar,
  Divider,
  CardActions,
  CardContent,
  CardMedia,
  Button
} from '@material-ui/core';
// import { 
//   ArrowRightAlt
// } from '@material-ui/icons';
import { buildBucket } from '../tomcat/tomcat'

const styles = (theme) =>  ({
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMediaImg: {
    width: 1068,
    height: 0,
    paddingTop: 697,
    margin: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  button: {
    width: "100%",
    height: "40%",
    display: "flex",
    justifyContent : "space-between",
    marginRight: theme.spacing(2)
  },
  buttonLabel: {
    fontSize: theme.spacing(2),
    textTransform: 'capitalize'
  },
  buttonIcon: {
    fontSize: theme.spacing(8)
  },
  headerTitle: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  headerAvatar: {
    backgroundColor: theme.palette.white,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  contentActionRow: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'row'
  },
  contentRow: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
    flexDirection: 'column',
    [theme.breakpoints.up('laptop')]: {
      flexDirection: 'row',
    },
  },
  contentAvatar: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  subColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent : "space-around",
    // backgroundColor: 'red',
    width: '100%',
    [theme.breakpoints.up('laptop')]: {
      width: '40%',
    },
  },
  contentColumn: {
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
    flexDirection: 'column'
  },
  player: {
    marginTop: theme.spacing(1),
    position: 'absolute',
    top: 0,
    left: 0,
  },
  playerWrapper: {
    marginTop: theme.spacing(1),
    position: 'relative',
    paddingTop: '56.25%' 
  }
})

// const getBreakpoint = (innerWidth) => {
//   switch(true) {
//     case (innerWidth <= 480):
//       return 'mobile'
//     case (innerWidth <= 640):
//       return 'tablet'
//     case (innerWidth <= 1024):
//       return 'laptop'
//     default:
//       return 'desktop'
//   }
// }

class DemoVideos extends Component {
  
  // state = {
  //   breakpoint: getBreakpoint(window.innerWidth),
  // }

  // componentDidMount() {
  //   const handler = () => {
  //     const newBreakpoint = getBreakpoint(window.innerWidth);
  //     console.log('newBreakpoint: ', newBreakpoint);
  //     if (this.state.breakpoint != newBreakpoint) {
  //       this.setState({breakpoint: newBreakpoint});
  //     }
  //   }
  //   window.addEventListener('resize', handler);
  // }

  render() {
    const  { 
      classes,
      breakpoint
    } = this.props

    console.log('this.props.breakpoint: ', breakpoint)
    return (

      <React.Fragment>
        <Card className={classes.cardRoot}>
        { (breakpoint === 'mobile')  ?
        <CardHeader
            title={
              <Typography component="h3" variant="h3" className={classes.headerTitle} color="secondary">
                Demo Videos
              </Typography>
            }
          /> 
          :
          <CardHeader
            avatar={
              <Avatar src={buildBucket(`gigimages`, `${process.env.COMPANY}/logo.png`)} className={classes.headerAvatar} />
            }
            title={
              <Typography component="h3" variant="h3" className={classes.headerTitle} color="secondary">
                Demo Videos
              </Typography>
            }
          /> 
        }
          
          <Divider variant="fullWidth" className={classes.divider} color="primary"/>
          <CardContent id="demoVideoWrapper">
            <div>
              <div className={classes.contentRow}>
                <div className={classes.subColumn} >
                <div className={classes.playerWrapper} >
                  <ReactPlayer
                    url="https://www.youtube.com/embed/EyS4NJbZvbw?fs=1"
                    width='100%'
                    height= '100%'
                    className={classes.player}
                  />
                </div>
                <div className={classes.playerWrapper} >
                  <ReactPlayer
                    url="https://www.youtube.com/embed/XcXjWa1CwJU?fs=1"
                    width='100%'
                    height= '100%'
                    className={classes.player}
                  />
                </div>
                <div className={classes.playerWrapper} >
                  <ReactPlayer
                    url="https://www.youtube.com/embed/pL5EZ_1UQ9w?fs=1"
                    width='100%'
                    height= '100%'
                    className={classes.player}
                  />
                </div>
                <div className={classes.playerWrapper} >
                  <ReactPlayer
                    url="https://www.youtube.com/embed/uq5aRbGvdlE?fs=1"
                    width='100%'
                    height= '100%'
                    className={classes.player}
                  />
                </div>
                <div className={classes.playerWrapper} >
                  <ReactPlayer
                    url="https://www.youtube.com/embed/nJgqtma3pLQ?fs=1"
                    width='100%'
                    height= '100%'
                    className={classes.player}
                  />
                </div>
             </div>
                <div className={classes.subColumn} >
                <div className={classes.playerWrapper} >
                <ReactPlayer
                url="https://www.youtube.com/embed/bnmQal-_AMY?fs=1"
                width='100%'
                height= '100%'
                className={classes.player}
              />
                </div>
                <div className={classes.playerWrapper} >
                <ReactPlayer
                url="https://www.youtube.com/embed/OkArPBG929M?fs=1"
                width='100%'
                height= '100%'
                className={classes.player}
              />
                </div>
                <div className={classes.playerWrapper} >
                <ReactPlayer
                url="https://www.youtube.com/embed/W6_5mF2dzEI?fs=1"
                width='100%'
                height= '100%'
                className={classes.player}
              />
                </div>
                <div className={classes.playerWrapper} >
                <ReactPlayer
                url="https://www.youtube.com/embed/c97_IopU4a8?fs=1"
                width='100%'
                height= '100%'
                className={classes.player}
              />
                </div>
                <div className={classes.playerWrapper} >
                  <ReactPlayer
                    url="https://www.youtube.com/embed/7FLvyk7Vr_0?fs=1"
                    width='100%'
                    height= '100%'
                    className={classes.player}
                  />
                </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

DemoVideos.propTypes = {
  classes: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   startLogin: () => dispatch(startLogin())
// });

export default connect(undefined)(withStyles(styles)(DemoVideos));
