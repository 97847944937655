import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect} from 'react-router-dom';
import { Link, animateScroll as scroll } from 'react-scroll';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, 
  Toolbar, 
  Typography, 
  Button,
 } from '@material-ui/core';

const drawerWidth = 90;

const styles = theme =>  ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  buttonLabel: {
    fontSize: theme.spacing(2.35),
    textTransform: 'capitalize'
  },
  toolBar: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: "space-between",
    // backgroundColor: 'blue',
    [theme.breakpoints.down('mobile')]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'pink',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'green',
    },
    [theme.breakpoints.up('laptop')]: {
      width: theme.breakpoints.values['laptop'] - theme.spacing(3 * 2),
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'yellow',
    },
    [theme.breakpoints.up('desktop')]: {
      width: theme.breakpoints.values['desktop'] - theme.spacing(3 * 2),
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'red',
    },
  },
})

class PublicAppBar extends Component {

  state = {
    redirect: '',
    myRef: null
  }

shouldComponentUpdate(nextProps, nextState) {
  console.log(`PublicAppBar shouldComponentUpdate`)
  if(nextState.redirect) {
    return true
  }
  return false
}

  // handleClick = (loginType) => {
  //   this.props.setLoginType(loginType);
  //   this.setState({
  //     ...this.state,
  //     redirect: `/login`
  //   })
  // }

  render() {
    const  { 
      classes,
      breakpoint,
      parent
    } = this.props

    console.log('PublicAppBar render with this.props.breakpoint: ', breakpoint)
    console.log('parent', parent)

    if(this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
      
    return (
      <div ref={ (ref) => this.state.myRef=ref }>
        <AppBar position="absolute" >
          <Toolbar  className={classes.toolBar}>
          { (breakpoint === 'mobile')  ?
            <Typography variant="h5" color="inherit" className={classes.grow}>
            <strong>{process.env.COMPANY}</strong>
            </Typography>
            :
            <Typography variant="h4" color="inherit" className={classes.grow}>
            <strong>{process.env.COMPANY}</strong>
            </Typography>
          }
            <div>
            <Link
            activeClass="active"
            to="demovideos"
            spy={true}
            smooth={true}
            // offset={-50}
            duration={500}
          >
          <Button           
          variant="text" 
          color="inherit" 
          classes={{ label: classes.buttonLabel }}
          onClick={() => {
            console.log('onClick')
          }}
        >
          Demo Videos
        </Button>
        </Link>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

PublicAppBar.propTypes = {
  classes: PropTypes.object.isRequired,  
  breakpoint: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => {
  console.log(`PublicAppBar mapStateToProps`)
  return {
  }
}

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(PublicAppBar));
// export default withStyles(styles)(PublicAppBar);