import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory, createHashHistory } from 'history'
import createHistory from 'history/createBrowserHistory';
import LandingPage from '../components/LandingPage';
import NotFoundPage from '../components/NotFoundPage';
// import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export const history = createHistory();
export const browserHistory = createBrowserHistory()
export const hashHistory = createHashHistory()


const AppRouter = () => {

  return(
  <Router history={browserHistory}>
  <div>
    <Switch>
      <PublicRoute path='/' component={LandingPage} exact={true}/>
      <Route component={NotFoundPage}/>
    </Switch>
  </div>
  </Router>
)};

export default AppRouter;