
const authReducerDefaultState = {
  uid: '',
  username: '',
  password: '',
  code: 0,
  codeText: '',
  loginType: ''
};

export default (state = {}, action) => {
  // console.log('./reducers/auth.js', state, action)
  switch(action.type) {
    case 'LOGIN':
      return {
        uid: action.uid
      };
    case 'SET_USER_NAME':
      return {
        ...state,
        username: action.username
      };
    case 'SET_PASSWORD':
      return {
        ...state,
        password: action.password
      };
    case 'SET_LOGIN_RES':
      return {
        ...state,
        code: action.code,
        codeText: action.codeText
      };
    case 'LOGOUT':
      return {};
    case 'SET_LOGIN_TYPE':
      return {
        ...state,
        loginType: action.loginType
      };
    default:
      return state;
  }
}