import React, { Component } from 'react';
// import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { 
  Container,
  Grid, 
  CssBaseline,
  Button
} from '@material-ui/core';
import PublicAppBar from '../components/PublicAppBar';
import PlatformPitch from './PlatformPitch';
import DemoVideos from './DemoVideos';

const styles = (theme) =>  ({
  appBarSpacer: theme.mixins.toolbar,
  layout: {
    width: '100%',
    // backgroundColor: 'blue',
    [theme.breakpoints.down('mobile')]: {
      // width: theme.breakpoints.values['mobile'] - theme.spacing(3 * 2),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'pink',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'green',
    },
    [theme.breakpoints.up('laptop')]: {
      width: theme.breakpoints.values['laptop'] - theme.spacing(3 * 2),
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'yellow',
    },
    [theme.breakpoints.up('desktop')]: {
      width: theme.breakpoints.values['desktop'] - theme.spacing(3 * 2),
      marginLeft: 'auto',
      marginRight: 'auto',
      // backgroundColor: 'red',
    },
  },
  componentSpacing: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('tablet')]: {
      padding: `${theme.spacing(8)}px 0`,
    },
  },
})

const getBreakpoint = (innerWidth) => {
  switch(true) {
    case (innerWidth <= 480):
      return 'mobile'
    case (innerWidth <= 640):
      return 'tablet'
    case (innerWidth <= 1024):
      return 'laptop'
    default:
      return 'desktop'
  }
}

class LandingPage extends Component {

  state = {
    breakpoint: getBreakpoint(window.innerWidth),
  }

  componentDidMount() {
    const handler = () => {
      const newBreakpoint = getBreakpoint(window.innerWidth);
      console.log('newBreakpoint: ', newBreakpoint);
      if (this.state.breakpoint != newBreakpoint) {
        this.setState({breakpoint: newBreakpoint});
      }
    }
    window.addEventListener('resize', handler);
  }

  render() {

    console.log('breakpoint: ',  this.state.breakpoint);
    const  { classes } = this.props
    return (
      <React.Fragment>
      <CssBaseline />
      <main>
        <div>
        <PublicAppBar breakpoint={this.state.breakpoint} parent={'LandingPage'} />
        <div className={classes.appBarSpacer} />
          <div className={classes.layout} >
          <div className={classes.componentSpacing}>
            <PlatformPitch breakpoint={this.state.breakpoint} />
          </div>
          <div id={'demovideos'} className={classes.componentSpacing}>
            <DemoVideos breakpoint={this.state.breakpoint} />
          </div>
        </div>
      </div>
      </main>
      </React.Fragment>
    )
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   startLogin: () => dispatch(startLogin())
// });

export default connect(undefined)(withStyles(styles)(LandingPage));
// export default connect(undefined, mapDispatchToProps)(LandingPage);