import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet'
import AppRouter, { history } from './routers/AppRouter';
import configureStore from './store/configureStore';
import 'normalize.css/normalize.css'
// import 'react-day-picker/lib/style.css';
// import 'react-dates/lib/css/_datepicker.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { buildBucket } from './tomcat/tomcat'
import { brown, teal, grey } from '@material-ui/core/colors'
import { responsiveFontSizes } from '@material-ui/core';

const store = configureStore();

const gotRefsTheme = createMuiTheme({
  breakpoints: {
    values: {
      mobile: 480,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
  palette: {
    primary: {
      light: process.env.PRIMARYLIGHT,
      main: process.env.PRIMARYMAIN,
      dark: process.env.PRIMARYDARK,
      contrastText: process.env.PRIMARYCONTRASTTEXT
    },
    secondary: {
      light: process.env.SECONDARYLIGHT,
      main: process.env.SECONDARYMAIN,
      dark: process.env.SECONDARYDARK,
      contrastText: process.env.SECONDARYCONTRASTTEXT,
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: grey[200],
        },
        '&:hover': {
          backgroundColor: grey[300],
        }
      }
    },
    MuiToggleButtonGroup: {
      root: {
        backgroundColor: process.env.SECONDARYDARK,
        '&:disabled': {
          color: 'grey',
          backgroundColor: grey[200],
        }
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: 'capitalize',
        margin: '4px',
        border: 'none',
        padding: '0px 12px',
        backgroundColor: process.env.SECONDARYMAIN,
        color: process.env.PRIMARYMAIN,
        fontSize: 13,
        '&:disabled': {
          color: 'grey',
          backgroundColor: grey[300],
        }
      },
    },
  }
});

export const gotRefsDisabledTheme = createMuiTheme({
  overrides: {
    MuiToggleButtonGroup: {
      root: {
        backgroundColor: grey[200],
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: 'capitalize',
        margin: '4px',
        border: 'none',
        padding: '0px 12px',
        backgroundColor: grey[300],
        color: 'grey',
        fontSize: 13,
      },
    },
  }
});


class AppComponent extends React.Component {

  // gotRefsTheme = responsiveFontSizes(gotRefsTheme);

  
  render() {
    // console.log('window.theme.typography', window.theme.responsiveFontSizes);
    return (
      <MuiThemeProvider theme={responsiveFontSizes(gotRefsTheme)}>
        <Helmet>
          <meta charset="UTF-8"></meta>
          <title>{`${process.env.COMPANY}`}</title>
          <link rel="icon" type="image/png" href={buildBucket(`gigimages`, `${process.env.COMPANY}/logo.png`)}/>
        </Helmet>
        <Provider store={store}> 
          <AppRouter />
        </Provider>
      </MuiThemeProvider>
    );
  }
}

let hasRendered = false;

if (!hasRendered) {
  ReactDOM.render(<AppComponent />, document.getElementById('app'));
  hasRendered = true;
}


