import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
// import userReducer from '../reducers/user';
// import memberReducer from '../reducers/member';
// import customerReducer from '../reducers/customer';
// import clientReducer from '../reducers/client';
// import administratorReducer from '../reducers/administrator'
// import providerReducer from '../reducers/provider';
// import customerClientsReducer from '../reducers/customerClients';
// import customerProvidersReducer from '../reducers/customerProviders'
// import locationsReducer from '../reducers/locations';
// import tasksReducer from '../reducers/tasks';
// import taskCategoriesReducer from '../reducers/taskCategories';
// import taskRequestsReducer from '../reducers/taskRequests';
// import positionsFilterReducer from '../reducers/positionsFilter';
// import closedPositionsFilterReducer from '../reducers/closedPositionsFilter';
// import taskrequestsFilterReducer from '../reducers/taskrequestsFilter';
// import jobsFilterReducer from '../reducers/jobsFilter';
// import drawerReducer from '../reducers/drawer';
// import outcomeCategoryReducer from '../reducers/outcomeCatgeories';
// import outcomeValueReducer from '../reducers/outcomeValues';
// import positionsCreateReducer from '../reducers/positionsCreate';
// import receivablesReducer from '../reducers/receivables';
// import invoicesFilterReducer from '../reducers/invoicesFilter';
// import realTimePositionsFilterReducer from '../reducers/realTimePositionsFilter';
// import providerCoordinatesReducer from '../reducers/providerCoordinates';
// import positionMetricsHistoryFilterReducer from '../reducers/positionMetricsHistoryFilter';
// import positionMetricsFutureFilterReducer from '../reducers/positionMetricsFutureFilter';
// import providersReducer from '../reducers/providers';
// import certificationsReducer from '../reducers/certifications';
// import providersFilterReducer from '../reducers/providersFilter';
// import clientsFilterReducer from '../reducers/clientsFilter';
// import reviewsFilterReducer from '../reducers/reviewsFilter';
// import providerPositionsDetailFilterReducer from '../reducers/providerPositionsDetailFilter';
// import reviewsReducer from '../reducers/reviews';
// import positionUpdateReducer from '../reducers/positionUpdate';
// import nearTermVacanciesFilterReducer from '../reducers/nearTermVacanciesFilter';
// import mostRecentPositionsFilterReducer from '../reducers/mostRecentPositionsFilter';
// import positionsToReviewFilterReducer from '../reducers/positionsToReviewFilter';
// import payPalCredentialsReducer from '../reducers/payPalCredentials'
// import payPalAuthTokenReducer from '../reducers/payPalAuthToken'
// import payPalInvoiceReducer from '../reducers/payPalInvoice';
// import payPalInvoiceUpdateReducer from '../reducers/payPalInvoiceUpdate'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer
      // user: userReducer,
      // member: memberReducer,
      // customer: customerReducer,
      // client: clientReducer,
      // provider: providerReducer,
      // administrator: administratorReducer,
      // customerClients: customerClientsReducer,
      // customerProviders: customerProvidersReducer,
      // taskCategories: taskCategoriesReducer,
      // locations: locationsReducer,
      // tasks: tasksReducer,
      // taskRequests: taskRequestsReducer,
      // positionsFilter: positionsFilterReducer,
      // closedPositionsFilter: closedPositionsFilterReducer,
      // taskrequestsFilter: taskrequestsFilterReducer,
      // jobsFilter: jobsFilterReducer,
      // drawer: drawerReducer,
      // outcomeCategories: outcomeCategoryReducer,
      // outcomeValues: outcomeValueReducer,
      // positionsCreate: positionsCreateReducer,
      // receivables: receivablesReducer,
      // invoicesFilter: invoicesFilterReducer,
      // realTimePositionsFilter: realTimePositionsFilterReducer,
      // providerCoordinates: providerCoordinatesReducer,
      // positionMetricsHistoryFilter: positionMetricsHistoryFilterReducer,
      // positionMetricsFutureFilter: positionMetricsFutureFilterReducer,
      // providers: providersReducer,
      // certifications: certificationsReducer,
      // providersFilter: providersFilterReducer,
      // clientsFilter: clientsFilterReducer,
      // reviewsFilter: reviewsFilterReducer,
      // providerPositionsDetailFilter: providerPositionsDetailFilterReducer,
      // reviews: reviewsReducer,
      // positionUpdate: positionUpdateReducer,
      // nearTermVacanciesFilter: nearTermVacanciesFilterReducer,
      // mostRecentPositionsFilter: mostRecentPositionsFilterReducer,
      // positionsToReviewFilter: positionsToReviewFilterReducer,
      // payPalCredentials: payPalCredentialsReducer,
      // payPalAuthToken: payPalAuthTokenReducer,
      // payPalInvoice: payPalInvoiceReducer,
      // payPalInvoiceUpdate: payPalInvoiceUpdateReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
  );
  return store;
}
