import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import { 
  Typography, 
  Card,
  CardHeader,
  Avatar,
  Divider,
  CardActions,
  CardContent,
  CardMedia,
  Button
} from '@material-ui/core';
// import { 
//   ArrowRightAlt
// } from '@material-ui/icons';
import { buildBucket } from '../tomcat/tomcat'

const styles = (theme) =>  ({
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMediaImg: {
    width: "100%",
    height: 0,
    paddingTop: "60%",
    // paddingTop: 697,
    // margin: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  button: {
    width: "100%",
    height: "40%",
    display: "flex",
    justifyContent : "space-between",
    marginRight: theme.spacing(2)
  },
  buttonLabel: {
    fontSize: theme.spacing(2),
    textTransform: 'capitalize'
  },
  buttonIcon: {
    fontSize: theme.spacing(8)
  },
  headerTitle: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  headerAvatar: {
    backgroundColor: theme.palette.white,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  contentRow: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
    flexDirection: 'column',
    // backgroundColor: 'red',
    [theme.breakpoints.up('laptop')]: {
      flexDirection: 'row',
      // backgroundColor: 'orange',
    },
  },
  contentAvatar: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: '2.5%',
    height: '2.5%',
  },
  subColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent : "space-around",
    // backgroundColor: 'pink',
    [theme.breakpoints.up('laptop')]: {
      width: '45%',
      // backgroundColor: 'blue',
    },
  },
  player: {
    // marginTop: theme.spacing(1),
    position: 'absolute',
    top: 0,
    left: 0,
  },
  playerWrapper: {
    // marginTop: theme.spacing(1),
    position: 'relative',
    paddingTop: '56.25%' 
  }
})

class PlatformPitch extends Component {
  
  render() {
    const  { 
      classes,
      breakpoint
    } = this.props

    console.log('this.props.breakpoint: ', breakpoint)

    return (
      <React.Fragment>
        <Card className={classes.cardRoot}>
        { (breakpoint === 'mobile')  ?
            <CardHeader
              title={
                <Typography component="h4" variant="h4" className={classes.headerTitle} color="secondary">
                  Let <strong>{process.env.COMPANY}</strong> help convert your two-way marketplace idea into a thriving enterprise using our <strong>comprehensive platform</strong>.
                </Typography>
              }
            /> 
            :
            <CardHeader
            avatar={
              <Avatar src={buildBucket(`gigimages`, `${process.env.COMPANY}/logo.png`)} className={classes.headerAvatar} />
            }
            title={
              <Typography component="h4" variant="h4" className={classes.headerTitle} color="secondary">
                Let <strong>{process.env.COMPANY}</strong> help convert your two-way marketplace idea into a thriving enterprise using our <strong>comprehensive platform</strong>.
              </Typography>
            }
          /> 
        }
            <Divider variant="fullWidth" className={classes.divider} color="primary"/>
            <CardMedia
              className={classes.cardMediaImg}
              image={buildBucket(`gigimages`, `${process.env.COMPANY}/eureka.png`)}
              title="Live from space album cover"
            />
          <Divider variant="fullWidth" className={classes.divider} color="primary"/>
          <CardContent id="demoVideoWrapper">
              <div className={classes.contentRow}>
                <div className={classes.subColumn} >
                  <Typography variant="h4" className={classes.headerTitle} color="secondary">
                    <strong>Overview</strong>
                  </Typography>
                  <div className={classes.playerWrapper} >
                  <ReactPlayer
                  url="https://www.youtube.com/embed/EyS4NJbZvbw?fs=1"
                  // width='500px'
                  width='100%'
                  height='100%'
                  className={classes.player}
                />
                  </div>

                </div>
                <div className={classes.subColumn} >
                  <Typography variant="h4" className={classes.headerTitle} color="secondary">
                    <strong>First Use Case</strong>
                  </Typography>
                  <div className={classes.playerWrapper} >
                  <ReactPlayer
                  url="https://www.youtube.com/embed/6xGGTBXjjYA?fs=1"
                  width='100%'
                  // width='500px'
                  height='100%'
                  className={classes.player}
                />
                  </div>

                </div>
              </div>
          </CardContent>
          {/*
          <CardActions className={classes.contentActionRow} />
          */ }
        </Card>
      </React.Fragment>
    )
  }
}

PlatformPitch.propTypes = {
  classes: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
};

// const mapDispatchToProps = (dispatch) => ({
//   startLogin: () => dispatch(startLogin())
// });

export default connect(undefined)(withStyles(styles)(PlatformPitch));
