

export const buildURL = (params, servlet) => {
  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/'

  const queryString = require('query-string');
  return(params ? process.env.API_URL + servlet + '?' + queryString.stringify(params) : process.env.API_URL + servlet)
}

export const buildBucket = (bucketname, filename) => {
  return(`${process.env.BUCKET_PREFIX}${bucketname}${process.env.BUCKET_DOT_ENDING}${filename}`)
}