import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({ 
  component: Component, 
  ...rest 
}) => (
  <Route {...rest} component={(props) => {
    console.log(`PublicRoute props: ${props && props.match && props.match.path ? props.match.path : 'Undefined'}`)
    return(
    <div>
      <Component {...props} />
    </div>
    )
  }} />
);

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(PublicRoute);
